import React, { useState } from "react"
import { Link } from "react-scroll"
import * as Scroll from "react-scroll"
import { Navbar, Nav } from "react-bootstrap"
import logo from "./valkoinenlogo.png"
import LogoFB from "../images/fb.svg"
import LogoInsta from "../images/vinsta.svg"

const headerOffset = -65

const NavBar = () => {
  const [expanded, setExpanded] = useState(false)
  var scroll = Scroll.animateScroll

  function scrollToTop() {
    scroll.scrollToTop()
  }

  return (
    <Navbar
      bg="#ac9393ff"
      expand="md"
      expanded={expanded}
      sticky="top"
      style={{
        background: "#263f35ff",
        padding: "0px 10px 0px 0",
      }}
    >
      <div>
        <Navbar.Brand
          as="a"
          style={{
            padding: "4px 28px",
            cursor: "pointer",
          }}
          onClick={() => scrollToTop()}
        >
          <img
            src={logo}
            width="50"
            height="50"
            alt="logo"
            style={{
              margin: "0px",
            }}
          />
        </Navbar.Brand>
        <Navbar.Brand
          href="https://www.facebook.com/hiusstudiogaia"
          target="_blank"
          style={{
            padding: "0px",
          }}
        >
          <LogoFB />
        </Navbar.Brand>
        <Navbar.Brand
          href="https://www.instagram.com/hiusstudiogaia/"
          target="_blank"
          style={{
            padding: "0px",
          }}
        >
          <LogoInsta />
        </Navbar.Brand>
      </div>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        onClick={() => setExpanded(expanded ? false : "expanded")}
      />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav.Item>
          <Link
            className="nav-link"
            onClick={() => setExpanded(false)}
            to="reservation"
            smooth={true}
            spy={true}
            offset={headerOffset}
            style={{
              padding: "20px 28px",
              cursor: "pointer",
            }}
          >
            Ajanvaraus
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link
            className="nav-link"
            to="pricelist"
            smooth
            offset={headerOffset}
            onClick={() => setExpanded(false)}
            style={{
              padding: "20px 29px",
              cursor: "pointer",
            }}
          >
            Hinnasto
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link
            className="nav-link"
            to="gallery"
            smooth
            offset={headerOffset}
            onClick={() => setExpanded(false)}
            style={{
              padding: "20px 28px",
              cursor: "pointer",
            }}
          >
            Galleria
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link
            className="nav-link"
            to="contact"
            smooth
            offset={headerOffset}
            onClick={() => setExpanded(false)}
            style={{
              padding: "20px 28px",
              cursor: "pointer",
            }}
          >
            Yhteystiedot
          </Link>
        </Nav.Item>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default NavBar

//<Link to="pricelist" smooth offset={headerOffset}>
//Hinnasto
//</Link>
