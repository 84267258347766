import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import logo from "./valkoinenlogo.png"

const LowerHeader = styled.h1`
  font-family: Lane, sans-serif;
  font-weight: 600;
  color: white;
  @media (max-width: 600px) {
    font-size: 25px;
  }
`

const Header = ({ siteTitle }) => {
  return (
    <header
      style={{
        backgroundColor: `#263f35ff`,
        backgroundSize: "cover"
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          maxHeight: 700,
          padding: `1.45rem 1.0875rem`,
          display: `block`,
        }}
      >
        <div className="wrapper">
          <img
            src={logo}
            alt="Logo"
            style={{
              flex: 1,
              maxHeight: "500px",
              resizeMode: "contain",
              marginBottom: "50px"
            }}
          />

          <LowerHeader>
              KAMPAAMO &nbsp; & &nbsp; PARTURI
          </LowerHeader>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
export default Header
